import React, { Component } from 'react';
import { Carousel, CarouselItem, CarouselControl } from 'reactstrap';

//Star Rating
import StarRatings from 'react-star-ratings';
import ProfilePic from '../../assets/images/Todzee/profileImage.jpg';

class ReviewSlider4 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
    };
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.reviews = [
      {
        id: 1,
        image: ProfilePic,
        name: 'Nimmi Mohanan',
        rating: 5,
        desc: "The staff is very friendly and caring. Very clean,safe environment and loving atmosphere. They keep me updated throughout the day on my daughter's care. So I highly recommend this preschool center(Todzee).",
      },
      {
        id: 2,
        image: ProfilePic,
        name: 'Vasuki Sivalingam',
        rating: 5,
        desc: 'Todzee stands out as the finest preschool in our experience. The teachers exhibit remarkable friendliness and care, and their approach to learning is genuinely impressive. Enrolling my 3-year-old son at Todzee has been a delightful experience.',
      },
      {
        id: 3,
        image: ProfilePic,
        name: 'Ranjeeta Singh',
        rating: 5,
        desc: 'We had an incredible experience with Todzee Preschool. My child Krishiv is in play group and he enjoys going school every day. And the dedication and passion of teachers and staff shine through in every interaction and activity. I am really amazed by thoughtfully planned activities at Todzee that promotes learning through play.',
      },
      {
        id: 4,
        image: ProfilePic,
        name: 'Manasa T',
        rating: 5,
        desc: "Happy with my son's overall development at TODZEE. Every Week there are some activities which motivates him to attend school daily. I Want to say a big 'Thank You' to all the teachers and special thanks to Yashaswini for there patience and passion to work with the little ones. We also appreciate the personal focus given on him to learn things.",
      },
      {
        id: 5,
        image: ProfilePic,
        name: ' Sarasa Raju',
        rating: 5,
        desc: 'Great efforts by all the Teachers, A very special thanks to the principal - Yashaswini, the way she is managing and handling the children and the school, My child is being groomed very well when compared to his previous schooling, We are  glad that we took this decision of admitting our son to TODZEE!! Kudos to the entire team!!!',
      },
      {
        id: 6,
        image: ProfilePic,
        name: 'Vogue Rhythmix',
        rating: 5,
        desc: 'The best preschool which provides best atmosphere to children to grow. Many activities are included to make kids learn practically. Too much hands on activities, dance, and developmental programmes. Recommended to all 2+ age group of children to have a memorable childhood.',
      },
    ];
  }

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === this.props.clients.length - 1
        ? 0
        : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === 0
        ? this.props.clients.length - 1
        : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }

  render() {
    return (
      <React.Fragment>
        <Carousel
          activeIndex={this.state.activeIndex}
          next={this.next}
          previous={this.previous}
        >
          {this.reviews.map((client, key) => (
            <CarouselItem
              key={key + 1}
              onExiting={this.onExiting()}
              onExited={this.onExited()}
            >
              <div key={key} className="customer-testi text-center">
                <p className="text-light para-dark h6 fst-italic mb-2">
                  " {client.desc} "
                </p>
                <StarRatings
                  rating={client.rating}
                  starRatedColor="#F17425"
                  numberOfStars={5}
                  name="rating"
                  starDimension="15px"
                  className="list-unstyled mb-0 mt-3"
                  starSpacing="1px"
                />
                <h6 className="text-light title-dark mt-1">
                  {client.name}
                  <small className="text-muted ms-1">{client.post}</small>
                </h6>
                <img
                  src={client.image}
                  style={{ height: 65, width: 65 }}
                  className="img-fluid avatar avatar-small rounded-circle mx-auto shadow"
                  alt=""
                />
              </div>
            </CarouselItem>
          ))}
          <CarouselControl direction="prev" onClickHandler={this.previous}>
            <span className="carousel-control-prev-icon"></span>
            <span className="sr-only">Previous</span>
          </CarouselControl>
          <CarouselControl direction="next" onClickHandler={this.next}>
            <span className="carousel-control-next-icon"></span>
            <span className="sr-only">Next</span>
          </CarouselControl>
        </Carousel>
      </React.Fragment>
    );
  }
}

export default ReviewSlider4;
