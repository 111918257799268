// React Basic and Bootstrap
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import Popup from '../../components/Layout/popup';

// Feature Images
import saas2 from '../../assets/images/Todzee/founderImg.jpg';

import Section from './Home';
import { agencyFeatureArray } from '../../common/data';

const IndexAgency = () => {
  return (
    <React.Fragment>
      {/* Feature */}
      <section className="section">
        <Container className="mt-100 mt-60">
          <Row className="align-items-center">
            <Col lg={6} md={6}>
              <img src={saas2} className="img-fluid" alt="" />
            </Col>

            <Col lg={6} md={6} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
              <div className="section-title ms-lg-3">
                <h4 className="title mb-4">About Us</h4>
                <p className="text-muted" style={{ textAlign: 'justify' }}>
                  Nurturing a child's potential in their early years lays a
                  crucial foundation for future growth. Positive encouragement
                  during these formative stages instills confidence, curiosity
                  and a sense of capability.<br></br> By fostering a supportive
                  environment, acknowledging their efforts, and providing
                  constructive feedback, we help build the self esteem and
                  resilience necessary for children to navigate challenges,
                  explore their interests and ultimately embark on a journey of
                  contiuous learning. <br></br>In every student I mentor, in
                  every initiative I lead, I see an opportunity to instill this
                  habit of excellence. Its about creating a culture where
                  excellence is the norm, not the exception. <br></br> To me,
                  excellence is about creating lasting impact, shaping minds and
                  nuturing leaders of tomorrow.
                </p>
                {/* <Link to="#" className="mt-3 h6 text-primary">
                  Find Out More{' '}
                  <i className="uil uil-angle-right-b align-middle"></i>
                </Link> */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default IndexAgency;
