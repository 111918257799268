import React, { useState } from 'react';
import { Container, Row, Col, Card, CardBody, Label } from 'reactstrap';
import { Link } from 'react-router-dom';

// import images
import blog1 from '../../assets/images/blog/01.jpg';
import Img1 from '../../assets/images/Todzee/GalleryImages/IMG-64.jpg';
import Img2 from '../../assets/images/Todzee/GalleryImages/IMG-57.jpg';
import Img3 from '../../assets/images/Todzee/GalleryImages/IMG-73.jpg';
import { object } from 'yup';
//import { useState } from 'react/cjs/react.production.min';

const Cards = () => {
  const [business, setbusiness] = useState([
    {
      id: 0,
      image: Img1,
      title: '(1.2 to 2.7 years) - Selp Focus',
      isOpen: false,
      description:
        'Social, Emotional, Language and Physical development is the prior focus.',
      description1:
        'Social, Emotional, Language and Physical development is the prior focus. As the child starts to get inquisitive about the visual content in his/her surrounding, excited about the sounds heard, and when the development of his/her kinetic energy starts to build stronger, it is important for us to trigger and help develop the basic personality of a child. While still developing the concept of sharing, children in this age group begin to understand the basics of taking turns and sharing toys with peers and exploring their environment.',
    },
    {
      id: 1,
      image: Img2,
      title: '(2.7 to 3.7 years) - MCLNFOCUs',
      isOpen: false,
      description:
        'Motor, Cognitive, Language and Numeracy development is the prior',
      description1:
        'Motor, Cognitive, Language and Numeracy development is the prior focus. As the child gradually has developed on the SELP aspect of understanding colors, visual presence of mind and emotional expressions, the energy then for the child is to kickstart his motor skills to help reach to whatever he is attracted. Then on the cognitive skills help the child to think, understand and memorize what he sees, reads or listens. By now the child starts to master many languages he is taught with. To top it all the child starts to develop numeracy skills.',
    },
    {
      id: 2,
      image: Img3,
      title: '(3.7 to 5.7 years) - L+C FOCUs',
      isOpen: false,
      description:
        'As the child is guided with the right developmental modules in mastering his',
      description1:
        "As the child is guided with the right developmental modules in mastering his social, emotional, language, physical, motor, cognitive and numeracy skills, Literacy and communication becomes the icing on the cake for a right personality to be molded. Literacy will help the child develop into a sensible, responsible and academically sound individual while communication helps the parent, teacher's and his clan to understand the kind of human being he/she is and the thought process which the child values.",
    },
  ]);
  return (
    <React.Fragment>
      <section className="section" id="ui-card">
        <Container>
          <Row>
            <Col lg={12} md={8} className="col-12">
              <Row>
                <div className="text-center">
                  <h2>Why Todzee?</h2>
                  <p>
                    Children at every stage of their growth require different
                    developmental needs and it is every parents and teachers
                    responsibility to cater to the right developmental need and
                    approach at the right time.
                  </p>
                </div>
                <Col>
                  <div className="component-wrapper rounded shadow">
                    <div className="p-4">
                      <Row>
                        {business.map((data, id) => (
                          <Col lg={4} className="col-12 mt-4 pt-2" key={id}>
                            <Card className="shadow rounded border-0 overflow-hidden">
                              <img
                                src={data.image}
                                className="img-fluid"
                                alt=""
                              />
                              <CardBody>
                                <h5 className="card-title">{data.title}</h5>

                                {data.isOpen == true
                                  ? data.description1
                                  : data.description}

                                {data.isOpen ? (
                                  <Label
                                    className="btn text-primary"
                                    onClick={() => {
                                      let l = [...business];
                                      l[data.id].isOpen = false;
                                      setbusiness(l);
                                    }}
                                  >
                                    Read Less
                                  </Label>
                                ) : (
                                  <Label
                                    className="btn text-primary"
                                    onClick={() => {
                                      let l = [...business];
                                      l[data.id].isOpen = true;
                                      setbusiness(l);
                                    }}
                                  >
                                    Read More
                                  </Label>
                                )}
                              </CardBody>
                            </Card>
                          </Col>
                        ))}
                      </Row>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Cards;
